import { template as template_ee8ad89b1b6a4caf9e07b9a0f952bfcc } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_ee8ad89b1b6a4caf9e07b9a0f952bfcc(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
