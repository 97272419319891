import { template as template_3b9242e1c3a540ce8ea3747509216d77 } from "@ember/template-compiler";
const FKText = template_3b9242e1c3a540ce8ea3747509216d77(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
